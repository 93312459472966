<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="账号/手机号" prop="mobile">
        <a-input v-model="form.mobile" placeholder="请输入账号/手机号，用于登录" />
      </a-form-model-item>
      <a-form-model-item label="密码" prop="password" v-if="!form.id">
        <a-input v-model="form.password" placeholder="请输入密码" :maxLength="20" />
      </a-form-model-item>
      <a-form-model-item label="昵称" prop="nickName">
        <a-input v-model="form.nickName" placeholder="请输入昵称" />
      </a-form-model-item>
      <a-form-model-item label="邮箱" prop="email">
        <a-input v-model="form.email" placeholder="请输入邮箱" />
      </a-form-model-item>
      <a-form-model-item label="公司名称" prop="companyName">
        <a-input v-model="form.companyName" placeholder="请输入公司名称" />
      </a-form-model-item>
      <!-- <a-form-model-item label="备注" prop="remark">
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="用户等级" prop="userLevel">
        <a-select placeholder="请选择" v-model="form.userLevel" style="width: 200px;">
          <a-select-option :value="0">S</a-select-option>
          <a-select-option :value="1">A</a-select-option>
          <a-select-option :value="2">B</a-select-option>
          <a-select-option :value="3">C</a-select-option>
        </a-select>
      </a-form-model-item> -->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getUser, addUser, updateUser } from '@/api/user/user'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data() {
    const validateNewPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'))
      } else if (!/^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$)([^\u4e00-\u9fa5\s]){6,20}$/.test(value)) {
        callback(new Error('请输入6-20位英文字母、数字或者符号（除空格），且字母、数字和标点符号至少包含两种'))
      } else {
        if (this.form.confirmPassword !== '') {
          this.$refs.form.validateField('confirmPassword')
        }
        callback()
      }
    }
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        avatar: '',
        birthday: '',
        companyName: '',
        creator: 0,
        email: '',
        invitationCode: '',
        isNotice: 0,
        mobile: '',
        modifier: 0,
        nickName: '',
        password: '',
        remark: '',
        sex: 0,
        status: 1,
        userSign: '',
        userType: 1
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        mobile: [
          { required: true, message: '账号/手机号，用于登录不能为空', trigger: 'blur' }
        ],
        avatar: [
          { required: true, message: '头像链接不能为空', trigger: 'blur' }
        ],
        password: [
          { required: true, validator: validateNewPass, trigger: 'blur' }
        ],
        nickName: [
          { required: true, message: '昵称不能为空', trigger: 'blur' }
        ],
        sex: [
          { required: true, message: '性别,0-保密 1-男 2-女不能为空', trigger: 'change' }
        ],
        userSign: [
          { required: true, message: '用户个性签名不能为空', trigger: 'blur' }
        ],
        isNotice: [
          { required: true, message: '是否推送 1-是 0-否不能为空', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '用户状态，1-正常，0禁用，禁用操作请注意清除token，同时不允许用户登录不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created() {
  },
  computed: {
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        avatar: '',
        birthday: '',
        companyName: '',
        creator: 0,
        email: '',
        invitationCode: '',
        isNotice: 0,
        mobile: '',
        modifier: 0,
        nickName: '',
        password: '',
        remark: '',
        sex: 0,
        status: 1,
        userSign: '',
        userType: 1
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getUser({ 'id': id }).then(response => {
        // const info = response.data
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateUser(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addUser(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
